<template>
  <div class="h-full w-full">
    <div class="flex flex-col items-center justify-center xsm:mt-5 sm:mt-20 space-y-10">
      <transition name="slide-up-delay">
        <div v-if="showHi" class="relative flex flex-col space-y-4 text-center animate-opacity">
          <h2 class="text-3xl xsm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl text-night bg-white px-4 border-4 border-night rounded-lg font-bold py-1 transform -rotate-2 relative z-10">
            Hi!
          </h2>
        </div>
      </transition>
      <transition name="slide-up-delay">
        <div v-if="showName" class="relative flex flex-col space-y-4 text-center animate-opacity">
          <h2 class="text-3xl xsm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl text-night bg-white px-4 border-4 border-night rounded-lg font-bold py-1 transform rotate-3 relative z-10">
            My Name is
          </h2>
        </div>
      </transition>
      <transition name="slide-up-delay">
        <div v-if="showConor" class="relative flex flex-col space-y-4 text-center animate-opacity">
          <div
            v-if="showGreen"
            class="absolute bg-retroGreen xsm:w-full w-3/4 h-3/4 rounded-lg xsm:-left-3 lg:-left-4 xsm:top-7 lg:top-10 z-30 transform -rotate-2 xsm:border-2 border-4 border-night"
          ></div>
          <div
            v-else
            class="absolute bg-none xsm:w-full w-3/4 h-3/4 rounded-lg xsm:-left-3 lg:-left-4 xsm:top-7 lg:top-10 z-30 transform -rotate-2 xsm:border-2 border-4 border-none"
          ></div>
          <div
            v-if="showPurple"
            class="absolute bg-retroPurple xsm:w-full w-3/4 h-3/4 rounded-lg xsm:-left-6 lg:-left-8  xsm:top-6 lg:top-10 z-20 transform -rotate-2 xsm:border-2 border-4 border-night"
          ></div>
          <div
            v-if="showOrange"
            class="absolute bg-retroOrange xsm:w-full w-3/4 h-3/4 rounded-lg xsm:-left-9 lg:-left-12 xsm:top-9 lg:top-14 z-10 transform -rotate-2 xsm:border-2 border-4 border-night"
          ></div>
          <h2 class="text-3xl xsm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl text-night bg-white px-4 xsm:border-2 border-4 border-night rounded-lg font-bold py-1 transform -rotate-2 relative z-40 animate-opacity">
            Conor
          </h2>
        </div>
      </transition>
      <transition name="slide-up-delay" class="pt-20 xsm:pt-10">
        <div v-if="showSoftwareEngineer" class="relative flex flex-col space-y-4 text-center animate-opacity">
          <h2 class="text-xl xsm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-night bg-white px-4 border-4 border-night rounded-lg font-bold py-1 relative z-10">
            I am a Software Developer.
          </h2>
        </div>
      </transition>
    </div>
  </div>
</template>




<script>
export default {
  data() {
    return {
      showHi: false,
      showName: false,
      showConor: false,
      showGreen: false,
      showPurple: false,
      showOrange: false,
      showSoftwareEngineer: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showHi = true;
      setTimeout(() => {
        this.showName = true;
        setTimeout(() => {
          this.showConor = true;
          setTimeout(() => {
            this.showGreen = true;
            setTimeout(() => {
              this.showPurple = true;
              setTimeout(() => {
                this.showOrange = true;
                setTimeout(() => {
                  this.showSoftwareEngineer = true;
                }, 500); // Adjusted timing
              }, 250);
            }, 250);
          }, 850);
        }, 1000);
      }, 1000);
    }, 100);
  },
};
</script>

<style>
@keyframes animate-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-opacity {
  animation: animate-opacity 1s ease-in-out;
}

.slide-up-delay-enter-active,
.slide-up-delay-leave-active {
  transition: opacity 1s ease-in-out;
}
.slide-up-delay-enter,
.slide-up-delay-leave-to {
  opacity: 0;
}
</style>
