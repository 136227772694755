<template>
  <div class="flex flex-col pr-2 text-snow rounded w-full text-4xl">
    <div>
      <div class="text-right">
        <button @click="closeMenu">x</button>
      </div>

      <div class="flex flex-col space-y-6 text-left">
        <div class="hover:font-bold">
          <router-link @click="closeMenu" to="/home">Home</router-link>
        </div>
        <div class="hover:font-bold">
          <router-link @click="closeMenu" to="/about">About</router-link>
        </div>
        <div class="hover:font-bold">
          <router-link @click="closeMenu" to="/projects">Projects</router-link>
        </div>
        <div class="hover:font-bold">
          <router-link @click="closeMenu" to="/resume">Experience</router-link>
        </div>
        <div class="hover:font-bold">
          <router-link @click="closeMenu" to="/contact">Contact</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["close"]);

const closeMenu = () => {
  emit("close");
};
</script>
