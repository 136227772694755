<template>
  <div class="flex w-full py-4 justify-end text-white">
    <div class="flex flex-col">
      <div v-if="isSmallScreen && !isMenuOpen" class="flex flex-col pb-14">
        <div>
          <div
            class="absolute top-4 m-0 py-.5 px-1.5 right-4 cursor-pointer text-5xl border-2 border-night rounded-md text-night bg-snow z-50"
            @click="toggleMenu"
          >
            <div class="">
              <div class="fas fa-bars text-night"></div>
            </div>
          </div>
        </div>
        <div
          class="absolute w-14 h-12 bg-retroPurple border-2 border-night absolute top-7 right-3 z-0 rounded-md"
        ></div>
      </div>
      <div
        v-if="isMenuOpen"
        class="fixed inset-0 z-50 bg-opacity-90 bg-gray-800"
      >
        <HamburgerMenu @close="closeMenu"></HamburgerMenu>
      </div>
    </div>
    <div
      v-if="!isSmallScreen"
      class="flex space-x-6 xsm:mr-6 sm:mr-12 md:mr-12 lg:mr-12 xl:mr-12"
    >
      <div
        class="text-white font-thin hover:font-bold xsm:text-[6px] md:text-base lg:text-lg xl:text-xl"
      >
        <router-link to="/home">Home</router-link>
      </div>
      <div
        class="text-white font-thin hover:font-bold xsm:text-[6px] md:text-base lg:text-lg xl:text-xl"
      >
        <router-link to="/about">About</router-link>
      </div>
      <div
        class="text-white font-thin hover:font-bold xsm:text-[6px] md:text-base lg:text-lg xl:text-xl"
      >
        <router-link to="/projects">Projects</router-link>
      </div>
      <div
        class="text-white font-thin hover:font-bold xsm:text-[6px] md:text-base lg:text-lg xl:text-xl"
      >
        <router-link to="/resume">Experience</router-link>
      </div>
      <div
        class="text-white font-thin hover:font-bold xsm:text-[6px] md:text-base lg:text-lg xl:text-xl"
      >
        <router-link to="/contact">Contact</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import HamburgerMenu from "./HamburgerMenu.vue";
import "@fortawesome/fontawesome-free/css/all.css";

const isMenuOpen = ref(false);
const isSmallScreen = ref(window.innerWidth <= 600); // Set your breakpoint here

import { ref } from "vue";

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}

function closeMenu() {
  isMenuOpen.value = false;
}
</script>
