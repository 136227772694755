<template>
  <div
    class="relative xsm:h-1/2 xsm:w-5/6 md:w-full md:h-full bg-white border-night rounded-md p-8 md:p-9"
  >
    <div class="flex items-center justify-center">
      <img
        :src="project.imageLink"
        alt="Photo"
        class="border-2 border-night rounded-lg shadow-lg mt-6 w-86 h-220 object-cover"
      />
    </div>
    <div class="text-start pt-10 flex flex-col">
      <div
        class="xsm:text-xl inline w-1/2 md:text-base lg:text-xl xl:text-3xl font-bold text-night border-b-4 border-retroGreen"
      >
        {{ project.title }}
      </div>
      <div
        class="mt-5 text-start align-start w-full font-medium xsm:text-base md:text-xl italic text-night"
      >
        {{ project.tag }}
      </div>
    </div>
    <div class="flex gap-4 mt-5 justify-center">
      <button
        v-if="open == 'learn'"
        class="border-2 p-2 border-black text-night xsm:text-base md:text-base rounded-lg font-bold hover:bg-gray-600 hover:text-white"
        @click="learnMore"
      >
        Learn More
      </button>
      <button
        v-else
        class="border-2 p-2 border-black text-night xsm:text-base md:text-base rounded-lg font-bold hover:bg-gray-600 hover:text-white"
        @click="learnMore"
      >
        Collapse
      </button>
      <button
        v-if="props.project.gitHub"
        @click="redirectToGitHub"
        class="border-2 p-2 border-black text-night xsm:text-base md:text-base rounded-lg font-bold hover:bg-gray-600 hover:text-white"
      >
        GitHub
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

// const props = defineProps(["project","learnMore"]);
const props = defineProps({
  project: Object,
  open: String,
});
const emit = defineEmits(["learn-more"]);

function learnMore() {
  emit("learn-more", props);
}

function redirectToGitHub() {
  const githubUrl = props.project.gitHub; // Replace with your GitHub repository URL
  window.open(githubUrl, "_blank");
}
</script>
