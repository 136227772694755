<template>
  <div class="xsm:pl-2 md:pl-8">
    <!-- Education -->
    <div clas="flex flex-col">
      <div class="relative">
        <div
          class="absolute bg-retroOrange xsm:h-[43%] lg:h-1/2 w-1/5 xsm:w-[50%] sm:w-1/5 2xl:w-1/6 xsm:rounded-md lg:rounded-lg lg:-left-2 xsm:top-4 xsm:-left-1 md:top-6 z-0 transform rotate-3"
        />
        <div
          class="xsm:text-4xl md:text-3xl lg:text-3xl xl:text-6xl text-night bg-white inline-block px-4 xsm:border-2 md:border-4 border-night rounded-lg mb-10 font-bold py-1 transform rotate-3 relative z-10"
        >
          Education
        </div>
      </div>
      <ResumeBulletPoint
        v-if="!isSmallScreen"
        year="2021-2025"
        company="University of Illinois (UIUC)"
        role="BS Computer Science"
        location="Champaign, IL"
        :points="illinois_points"
      ></ResumeBulletPoint>
      <ResumeBulletPoint
        v-else
        year="2021-25"
        company="UIUC"
        role="CS [BS]"
        location="Champaign"
        :points="illinois_points"
      ></ResumeBulletPoint>
    </div>
    <div class="pt-10">
      <div class="relative w-1/2">
        <div
          class="absolute bg-retroBlue xsm:h-[43%] lg:h-1/2 w-1/5 xsm:w-[59%] sm:w-1/5 2xl:w-1/6 xsm:rounded-md lg:rounded-lg -left-1 xsm:top-4 md:top-6 z-0 transform -rotate-2"
        ></div>
        <div
          class="xsm:text-4xl md:text-3xl lg:text-3xl xl:text-6xl text-night bg-white inline-block px-4 xsm:border-2 md:border-4 border-night rounded-lg mb-10 font-bold py-1 transform -rotate-2 relative z-10"
        >
          Work
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <ResumeBulletPoint
          v-if="!isSmallScreen"
          year="2023"
          company="Shedd Capital, LLC"
          role="Software Engineering Intern"
          location="Chicago, IL"
          :points="shedd_points"
        ></ResumeBulletPoint>
        <ResumeBulletPoint
          v-else
          year="2023"
          company="Shedd Capital "
          role="SWE"
          location="Chicago"
          :points="shedd_points"
        ></ResumeBulletPoint>
        <ResumeBulletPoint
          v-if="!isSmallScreen"
          year="2022-2023"
          company="Grainger College of Engineering"
          role="CS124 Course Associate"
          location="Champaign, IL"
          :points="cs_points"
        ></ResumeBulletPoint>
        <ResumeBulletPoint
          v-else
          year="2022-23"
          company="UIUC"
          role="Course Associate"
          location="Champaign"
          :points="cs_points"
        ></ResumeBulletPoint>
      </div>
    </div>
  </div>
</template>

year: String, company: String, role: String, location: String,

<script setup>
import { ref } from "vue";

import ResumeBulletPoint from "./ResumeBulletPoint.vue";

const shedd_points = ref([
  "Deployed 6 full-stack Django REST framework modules to company’s internal application for CRM and company research business operations using Vue.js, PostgreSQL",
  "Increased Python web crawler efficacy from 60% to 80% allowing account managers to utilize full text search across thousands of companies within internal database",
  "Onboarded 200+ companies to internal PostgreSQL database using Beautiful Soup, Selenium through ETL",
  "Developed, presented, and facilitated educational coding challenge for future Chicago area hackathon",
  "Resolved 3 front-end breakpoints, enhancing usability by developing infinite scroll dropdown solution",
]);

const cs_points = ref([
  "Spent 10+ hours weekly performing in-person/virtual tutoring on Java and Kotlin based problems",
  "Created 5 weekly engaging and educational walkthrough videos on homework problems and quiz prep",
  "Collaborated with 8 course assistants to identify weaknesses in curriculum and provide student support",
]);

const illinois_points = ref([
  "Edmund J. James Scholar Honors Program",
  "Minor in Business",
]);

const isSmallScreen = ref(window.innerWidth <= 600); // Set your breakpoint here
</script>
