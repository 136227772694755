<template>
  <div
    v-if="!isSmallScreen"
    class="bg-white border-4 border-night w-full h-full flex flex-col rounded-md justify-center align-center items-center"
  >
    <div class="border-4 border-night rounded-md w-3/5 h-3/5 mt-5">
      <vue-plyr>
        <div
          data-plyr-provider="youtube"
          allow="autoplay"
          :data-plyr-embed-id="moreInfoObject.video"
        ></div>
      </vue-plyr>
    </div>

    <div class="flex flex-col w-3/5 mt-5">
      <div class="xsm:text-[6px] md:text-base font-medium text-night">
        Programs/Languages:
        <span class="italic text-retroBlue">{{ moreInfoObject.skills }}</span>
      </div>
      <div
        class="mt-5 xsm:text-[6px] md:text-base"
        v-html="descriptionWithLineBreaks"
      ></div>
    </div>
  </div>
  <div
    v-else
    class="bg-white border-4 border-night w-full h-full flex flex-col rounded-md justify-center align-center items-center"
  >
    <div class="flex flex-auto pt-4 w-full px-4">
      <div class="flex space-x-2">
        <button
          @click="redirectToGitHub"
          class="border-2 p-2 bg-white border-retroGreen text-night xsm:text-base md:text-base rounded-md font-bold hover:bg-gray-600 hover:text-white"
        >
          GitHub
        </button>
        <button
          @click="redirectToYouTube"
          class="border-2 p-2 bg-white border-retroRed text-night xsm:text-base md:text-base rounded-md font-bold hover:bg-gray-600 hover:text-white"
        >
          YouTube
        </button>
      </div>
      <button
        @click="collapse"
        class="align-right relative top-0 right-0 p-2 bg-white text-night xsm:text-2xl md:text-base font-bold hover:bg-gray-600 hover:text-white ml-auto"
      >
        X
      </button>
    </div>

    <div class="flex flex-col w-5/6 mt-5">
      <div class="text-xl">
        Programs/Languages:
        <span class="italic text-retroBlue">{{ moreInfoObject.skills }}</span>
      </div>
      <div class="mt-5 text-xl" v-html="descriptionWithLineBreaks"></div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";

const props = defineProps(["moreInfoObject"]);

const emit = defineEmits(["collapse"]);

function collapse() {
  emit("collapse");
}
const descriptionWithLineBreaks = props.moreInfoObject.description.replace(
  /\n/g,
  "<br>"
);
const isSmallScreen = ref(window.innerWidth <= 600);

function redirectToGitHub() {
  const githubUrl = props.moreInfoObject.gitHub; // Replace with your GitHub repository URL
  window.open(githubUrl, "_blank");
}

function redirectToYouTube() {
  const youtubeUrl = props.moreInfoObject.youtube; // Replace with your GitHub repository URL
  window.open(youtubeUrl, "_blank");
}
</script>
