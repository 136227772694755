import { createApp } from "vue";
import Particles from 'vue3-particles'
import VuePlyr from 'vue-plyr'

import 'vue-plyr/dist/vue-plyr.css'

import App from "./App.vue";
import router from "./router.js";
import './index.css'
import '../styles.scss'

const app = createApp(App);

app.use(router);
app.use(Particles);
app.use(VuePlyr);

app.mount("#app");
