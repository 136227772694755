<template>
  <div class="">
    <div
      class="flex flex-col items-center justify-center font-thin text-white xsm:mt-10 sm:mt-16"
    >
      <div class="text-4xl xsm:text-4xl md:text-6xl text-center">
        Always open for new
        <span class="text-retroGreen font-medium">opportunities</span>,
        <span class="text-retroRed font-medium">ideas</span>, and
        <span class="text-retroBlue font-medium">books</span>!
      </div>
      <div class="flex w-full items-center justify-center">
        <div class="flex flex-col mt-6 items-center">
          <div class="relative">
            <!-- Red div to create a card-like effect behind the button -->
            <div
              class="absolute bg-retroRed w-full xsm:h-[62%]  md:h-3/5 xsm:rounded-md md:rounded-lg -left-2 md:top-16 z-0 xsm:top-12 border-2 border-night"
              style="z-index: -1"
            ></div>
            <a :href="'mailto:conorcunnin@gmail.com'">
              <button
                class="hover:font-extrabold relative mt-10 bg-white text-black text-center md:text-start px-6 md:px-10 py-2 md:py-3 text-3xl xsm:text-5xl md:text-6xl font-bold xsm:border-2 md:border-4 rounded-lg border-black z-10"
              >
                Email Me
              </button>
            </a>
          </div>
          <div class="flex space-x-4 mt-10 relative">
            <div
              class="absolute bg-retroBlue w-1/3 xsm:w-[37%] sm:w-1/3 md:w-2/5 xsm:h-[84%] md:h-1/2 xsm:rounded sm:rounded-md xsm:right-2 xsm:left-3 sm:left-3 xsm:top-2 md:top-5 z-0"
            ></div>
            <a
              class="relative bg-white text-black font-medium hover:font-bold px-2 md:px-3 xsm:border-2 md:border-4 border-black xsm:rounded sm:rounded-lg text-md xsm:text-lg md:text-base"
              href="https://www.linkedin.com/in/conor-cunningham-014b7321a/"
              target="_blank"
              >LinkedIn</a
            >
            <div
              class="absolute bg-retroGreen w-1/3 xsm:w-[37%] sm:w-1/3 md:w-2/5 xsm:h-[84%] md:h-1/2 xsm:rounded sm:rounded-md xsm:right-2 md:right-1 xsm:top-2 md:top-5 z-0"
            ></div>
            <a
              class="relative bg-white text-black font-medium hover:font-bold px-2 md:px-3 xsm:border-2 md:border-4 border-black xsm:rounded sm:rounded-lg text-md xsm:text-lg md:text-base"
              href="https://github.com/crc1200"
              target="_blank"
              >GitHub</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>