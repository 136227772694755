<template>
  <div
    class="border-4 border-night text-night rounded-2xl p-4 font-bold"
    :class="randomBackgroundColorClass"
  >
    <div v-for="point in props.points" :key="point">
        <li> {{ point }}</li>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const backgroundColorOptions = ["bg-retroYellow", "bg-retroOrange", "bg-retroRed", "bg-retroBlue", "bg-retroGreen"];
const randomBackgroundColor = computed(() => {
  const randomIndex = Math.floor(Math.random() * backgroundColorOptions.length);
  return backgroundColorOptions[randomIndex];
});

const randomBackgroundColorClass = computed(() => {
  return randomBackgroundColor.value;
});

const props = defineProps({
  points: Array,
});
</script>
