<template>
  <div>
    <div v-if="isSmallScreen" class="flex flex-col xsm:w-full sm:w-[75%] xsm:border-2 sm:border-4 border-snow xsm:rounded sm:rounded-xl bg-snow">
      <div
        class="relative z-20 flex font-semibold xsm:text-[6px] xsm:text-base sm:text-sm md:text-base lg:text-lg xl:text-xl xsm:space-x-1 lg:space-x-4 text-night bg-snow p-4"
        :class="{ 'border-b-0': isVisible }"
      >
        <div class="w-1/6">
          <button
            v-if="!isVisible"
            @click="toggleView"
            class="justify-center items-center align-center mt-2 border-2 border-night bg-retroGreen xsm:px-2 sm:py-1 sm:px-2 rounded-lg"
          >
            +
          </button>
          <button
            v-else
            @click="toggleView"
            class="justify-center items-center align-center mt-2 border-2 border-night bg-retroRed py-1 px-2 rounded-lg"
          >
            -
          </button>
        </div>
        <div class="w-1/3 flex items-center">{{ props.year }}</div>
        <div class="w-1/3 flex items-center">{{ props.company }}</div>
        <div class="w-1/3 flex items-center">{{ props.role }}</div>
      </div>
      <ResumeMoreInfo v-if="isVisible" :points="props.points"></ResumeMoreInfo>
    </div>
    <div v-else class="flex flex-col xsm:w-full sm:w-[75%] xsm:border-2 sm:border-4 border-snow xsm:rounded sm:rounded-xl bg-snow">
      <div
        class="relative z-20 flex font-semibold xsm:text-[6px] xsm:text-base sm:text-sm md:text-base lg:text-lg xl:text-xl xsm:space-x-1 lg:space-x-4 text-night bg-snow p-4"
        :class="{ 'border-b-0': isVisible }"
      >
        <div class="w-1/6">
          <button
            v-if="!isVisible"
            @click="toggleView"
            class="justify-center items-center align-center mt-2 border-2 border-night bg-retroGreen xsm:px-2 sm:py-1 sm:px-2 rounded-lg"
          >
            +
          </button>
          <button
            v-else
            @click="toggleView"
            class="justify-center items-center align-center mt-2 border-2 border-night bg-retroRed py-1 px-2 rounded-lg"
          >
            -
          </button>
        </div>
        <div class="w-1/6 flex items-center">{{ props.year }}</div>
        <div class="w-1/6 flex items-center">{{ props.company }}</div>
        <div class="w-2/6 flex items-center">{{ props.role }}</div>
        <div v-if="!isSmallScreen" class="w-1/6 flex items-center">{{ props.location }}</div>
      </div>
      <ResumeMoreInfo v-if="isVisible" :points="props.points"></ResumeMoreInfo>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import ResumeMoreInfo from "./ResumeMoreInfo.vue";

const isVisible = ref(false);

function toggleView() {
  isVisible.value = !isVisible.value;
}

const isSmallScreen = ref(window.innerWidth <= 600); // Set your breakpoint here


const props = defineProps({
  year: String,
  company: String,
  role: String,
  location: String,
  points: Array,
});
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 1s;
}

.slide-fade-leave-active {
  transition: all 1s;
}

.slide-fade-enter-from {
  transform: translateY(-40px);
  opacity: 100;
}
.slide-fade-leave-to {
  transform: translateY(-40px);
  opacity: 100;
}
</style>
