import { createRouter, createWebHistory } from "vue-router";

import Projects from "./components/ProjectsPage.vue";
import Home from "./components/HomePage.vue";
import Resume from "./components/ResumePage.vue";
import Contact from "./components/ContactPage.vue";
import AboutMe from "./components/AboutMe.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    {
      name: "home",
      path: "/home",
      meta: { needsAuth: true },
      components: { default: Home },
    },
    {
      name: "about",
      path: "/about",
      meta: { needsAuth: true },
      components: { default: AboutMe },
    },
    {
      name: "projects",
      path: "/projects",
      meta: { needsAuth: true },
      components: { default: Projects },
    },
    {
      name: "contact",
      path: "/contact",
      meta: { needsAuth: true },
      components: { default: Contact },
    },
    {
      name: "resume",
      path: "/resume",
      meta: { needsAuth: true },
      components: { default: Resume },
    },
  ],
  linkActiveClass: "active",
});

export default router;
