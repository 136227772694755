<template>
  <div>
    <div v-if="!isSmallScreen" class="flex h-screen">
      <div class="flex-col items-center justify-center">
        <div class="relative md:ml-20 xsm:ml-5">
          <div
            class="absolute bg-retroPurple xsm:h-1/3 lg:h-1/2 w-1/5 sm:w-1/5 2xl:w-1/6 xsm:rounded-md lg:rounded-lg xsm:-left-1 air:-left-1 md:-left-1 lg:-left-2 xsm:top-3 md:top-6 z-0 transform rotate-3"
          ></div>
          <div
            class="xsm:text-4xl md:text-3xl lg:text-3xl xl:text-6xl text-night bg-white inline-block px-4 xsm:border-2 md:border-4 border-night rounded-lg mb-10 font-bold py-1 transform rotate-3 relative z-10"
          >
            About Me
          </div>
        </div>
        <div class="flex justify-center align-center items-center relative">
          <div
            class="xsm:text-[6px] sm:text-base md:text-lg lg:text-xl xl:text-2xl font-extralight xsm:mr-1 md:mr-12 ml-5 bg-snow text-night bg-snow border-4 border-night rounded-lg p-10 w-1/3"
          >
            <div class="text-2xl font-bold">
              Student at UIUC [Computer Science]
            </div>
            <br />
            <div>
              I have professional experience in full-stack web development and
              data analysis. I have also developed a machine learning mobile app
              with my twin brother, Patrick, that helps college students cook
              dinner.
            </div>
            <br />
            <div class="mt-10">
              I am an avid reader, and I have recently developed a passion for
              endurance sports. This summer, I completed my first Olympic
              Distance Triathlon, and will run the 2024 Chicago Marathon.
            </div>
          </div>

          <img
            src="../assets/website.png"
            alt="HeadShot"
            class="w-1/3 h-1/3 object-contain z-40 mr-2 rounded-md border-2 border-night"
          />
          <div
            class="w-1/3 xsm:h-4/6 xs:h-5/6 air:h-4/6 md:h-3/6 lg:h-[78%] 2xl:5/6 bg-retroPurple xsm:border md:border-2 border-night absolute xsm:top-12 xs:top-9 air:top-24 md:top-[152px] lg:top-28 xsm:right-4 md:right-40 2xl:right-56 z-10 rounded-md"
          ></div>
          <div
            class="w-1/3 xsm:h-4/6 xs:h-5/6 air:h-4/6 md:h-3/6 lg:h-[78%] 2xl:5/6 bg-retroOrange xsm:border md:border-2 border-night absolute xsm:top-11 xs:top-8 air:top-[88px] md:top-36 lg:top-24 xsm:right-6 md:right-44 2xl:right-60 z-20 rounded-md"
          ></div>
          <div
            class="w-1/3 xsm:h-4/6 xs:h-5/6 air:h-4/6 md:h-3/6 lg:h-[78%] 2xl:5/6 bg-retroGreen xsm:border md:border-2 border-night absolute xsm:top-10 xs:top-7 air:top-20 md:top-[136px] lg:top-20 xsm:right-8 md:right-48 2xl:right-64 z-30 rounded-md"
          ></div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col h-screen">
      <div class="flex-col items-center justify-center align-center">
        <div class="relative md:ml-20 xsm:ml-5">
          <div
            class="absolute bg-retroPurple xsm:w-[54%] md:w-1/5 xsm:h-1/2 air:h-1/3 md:h-1/3 lg:h-1/2 xsm:rounded-md md:rounded-lg xsm:-left-1 air:-left-1 md:-left-1 lg:-left-2 xsm:top-3 md:top-6 z-0 transform rotate-3"
          ></div>
          <div
            class="xsm:text-4xl md:text-3xl lg:text-3xl xl:text-6xl text-night bg-white inline-block px-4 xsm:border-2 md:border-4 border-night rounded-lg mb-10 font-bold py-1 transform rotate-3 relative z-10"
          >
            About Me
          </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-center">
            <img
              src="../assets/website.png"
              alt="HeadShot"
              class="w-5/6 h-5/6 rounded-lg xsm:border-2 sm:border-4 border-night z-40"
            />

            <div
              class="w-[82%] h-[57%] bg-retroGreen border border-night top-[190px] left-12 absolute z-30 rounded-md"
            ></div>
            <div
              class="w-[81%] h-[57%] bg-retroOrange border border-night top-[180px] left-16 absolute z-20 rounded-md"
            ></div>

            <!-- <div
              class="w-[92%] h-[63%] bg-snow border border-night top-[180px] absolute z-20 rounded-full"
            ></div> -->
          </div>
          <div class="flex flex-col justify-center align-center items-center">
            <div
              class="bg-snow xsm:border-2 sm:border-4 border-night rounded-lg text-night mt-20 p-6 w-10/12 justify-center align-center items-center z-30"
            >
              <div class="text-2xl font-bold text-center">Student at UIUC</div>
              <div class="text-2xl font-bold text-center">
                [Computer Science]
              </div>
              <br />
              <div class="text-xl">
                I have professional experience in full-stack web development and
                data analysis.
              </div>
              <br />
              <div class="text-xl">
                I am an avid reader, and I have recently developed a passion for
                endurance sports. This summer, I completed my first Olympic
                Distance Triathlon, and will run the 2024 Chicago Marathon.
              </div>
            </div>
            <div
              class="w-[78%] h-5/6 bg-retroBlue border border-night absolute z-20 left-5 top-[580px] rounded-md"
            ></div>
          </div>
        </div>

        <div class="flex justify-center align-center items-center relative">
          <div
            class="xsm:text-[6px] sm:text-base md:text-lg lg:text-xl xl:text-2xl text-white font-extralight xsm: mr-1 md:mr-12 ml-5"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const isSmallScreen = ref(window.innerWidth <= 600); // Set your breakpoint here
</script>
