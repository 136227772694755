<template>
  <div class="">
    <Particles id="tsparticles" :particlesInit="particlesInit" :options="particlesOptions" />

    <NavBar></NavBar>
    <router-view></router-view>
 
  </div>
</template>

<script setup>
import NavBar from './components/navbar/NavBar.vue';

import { loadStarsPreset } from 'tsparticles-preset-stars'

const particlesInit = async (engine) => {
  await loadStarsPreset(engine);
};

const particlesOptions = {
  preset: "stars",
  fullScreen: {
    enable: true,
    zIndex: -1,
  },
};
</script>